import React, { useState } from 'react';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SideBar from './SideBar';
import Events from './Events';

const ToggleBar = ({ details, updateFilters }) => {
    const [state, setState] = useState({
        isPaneOpenLeft: false,
    });

    return (
        <div id="map-container">
            <div style={{ marginTop: "32px" }}>
                <button onClick={() => setState({ isPaneOpenLeft: true })}>
                    Toggle Btn
                </button>
            </div>
            <SlidingPane
                closeIcon={<div>Custom Icon To close</div>}
                isOpen={state.isPaneOpenLeft}
                title="Hey, it is optional pane title. I can be React component too."
                from="left"
                width="700px"
                onRequestClose={() => setState({ isPaneOpenLeft: false })}
            >
                <Container fluid style={{ display: "flex",height : "100%" }}>
                    <Row className="flex-grow-1">
                        <Col xl={6} xs={12} className="d-flex flex-column">
                            <h2>Filter Results</h2>
                            <div className="flex-grow-1 d-flex">
                                <SideBar updateFilters={updateFilters} details={details} className="w-100" />
                            </div>
                        </Col>
                        <Col xl={6} xs={12} className="d-flex flex-column">
                            <h2>Nearby Reservations</h2>
                            <div className="flex-grow-1 d-flex">
                                <Events details={details} updateFilters={updateFilters} cardsPerRow={1} className="w-100" />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </SlidingPane>
        </div>
    );
};

export default ToggleBar;

