import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import badminton from '../assets/badminton.jpg'
import logo from '../logo.svg'
import { useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';


const Events= ({details,updateFilters, cardsPerRow }) => {

  const { type, isBookable ,geo_radial, sportNr, classNr, limit, page,activityTypes,age_lte, age_gte, priceAdult_lte, gender,output } = details;
  const [output2,setOutput2] = useState(output)
  const navigate = useNavigate();

  console.log(activityTypes,"Types")
  const handleClick = (id)=>{

    navigate(`/home/event/${id}`)

}
  useEffect(()=>{
    const queryParams = new URLSearchParams({
      type,
      isBookable : isBookable.toString(),
      sportNr: sportNr.toString(),
      classNr: classNr.toString(),
      geo_radial: geo_radial,
      limit : limit.toString(),
      page : page.toString(),
      });

    if(priceAdult_lte){
      queryParams.append('priceAdult_lte',priceAdult_lte.toString())
    }

    //if(age_lte){
    //  queryParams.append('age_lte',age_lte.toString())
    //}

    //if(age_gte){
    //  queryParams.append('age_gte',age_gte.toString())
    //}

    if(gender){
      queryParams.append('gender',gender.toString())
    }

    if (Array.isArray(activityTypes)) {
      const activityParams = activityTypes.map((items) => {
        if (items.split(' ').length > 1) {
          return items.split(' ').join('%20');
        }
        return items;
      });

      const activityParamString = activityParams.join(',');
      queryParams.append('activityTypes', activityParamString);
      console.log(activityParamString, "Params");
    }


    const url =`https://api-test.findmyfacility.com/v1/events?${queryParams}`
    //const egurl ='https://api-test.findmyfacility.com/v1/events?isBookable=false&geo_radial=51.511848%2C-0.110344%2C5&limit=10&page=1&sportNr=0&classNr=0'
    fetch(url, {
      method: 'GET',
      headers: { "Content-Type": "application/json" }
    })
        .then(res => {
          if (!res.ok) {
            // If the response is not OK, throw an error with the status text
            throw new Error(`HTTP error! Status: ${res.status} ${res.statusText}`);
          }
          return res.json();
        })
        .then(data => {
          // Process the received data
          setOutput2(data.events);
        })
        .catch(error => {
          // Handle both HTTP and network errors
          console.error('An error occurred:', error.message);
          alert('Invalid location or no location entered');

          // Optional: Set a state to indicate an error has occurred
          // setError(true);
        });


  },[type,sportNr,classNr,geo_radial,limit,page,priceAdult_lte,gender,activityTypes])

  useEffect(()=>{

    updateFilters({output2})

  },[output2])
  return (
    output ? 
    <Row className="g-4 mt-2">
  {output2.map((events) => (
    <Col key={events.id} xs={12} md={cardsPerRow===1 ? 12 : 6}>
      <Card onClick={()=>{handleClick(events.id)}}  >
      <div className="d-flex justify-content-center align-items-center"> {/* Center the image */}
              <Card.Img
                variant="top"
                src={events.imageUrl && events.imageUrl.length > 0 ? events.imageUrl[0] : badminton}
                style={{
                  height: 200,
                  width: '100%',
                }}
              />
        </div>
        <Card.Body className="d-flex flex-column">
          <div className="d-flex justify-content-between align-items-start">
            <div>
              <Card.Title>{events.name}</Card.Title>
            </div>
            <div>
              <Card.Text>${events.price}</Card.Text>
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <Card.Text>{events.providerName}</Card.Text>
            </div>
            <div>
              <Card.Text>{events.distance}</Card.Text>
            </div>
          </div>
        </Card.Body>
      </Card>
    </Col>
  ))}
</Row> : <h1>No Data</h1>
)}


export default Events;