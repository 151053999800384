import React from 'react';
import {createRoot} from 'react-dom/client';
import {APIProvider, Map, Marker , AdvancedMarker , Pin , useMarkerRef, InfoWindow} from '@vis.gl/react-google-maps';
import map from '../assets/map.png'
import '../assets/styles/SampleMap.css'
import fmf from '../assets/Findmyfacility_RGB_Portrait-Without_Text.png'
const SampleMap = ({details}) => {


    const {output} = details


    const [selectedMarker, setSelectedMarker] = React.useState(null);

    const coordinates=[
    { lat: 51.510848, lng: -0.110344 }, // South
    { lat: 51.511848, lng: -0.109344 }, // East
    { lat: 51.511848, lng: -0.111344 }, // West
    { lat: 51.512348, lng: -0.109844 }, // North-East
    { lat: 51.510348, lng: -0.109844 }, // South-East
    { lat: 51.510348, lng: -0.111844 }, // South-West
    // North-West
];
    const handleMouseOver = (items) =>{

        setSelectedMarker(items)

    }

    const handleMouseOut = () =>{

        setSelectedMarker(null);

    }

    const mapStyles = [
        {
            "featureType": "road",
            "stylers": [
                { "visibility": "on" }
            ]
        }
        ]

    return(

        <APIProvider apiKey='AIzaSyCjYemnQG_XW3JLmu3HifVheyWXFrFrjf4'>
        <Map
            style={{width: '100vw', height: '100vh'}}
            defaultCenter={{lat: 51.511848, lng: -0.110344}}
            defaultZoom={15}
            mapId={'DEMO_MAP_ID'}
            //options ={{styles : mapStyles}}
        >

            {output.map((items, index) => {


                return (
                    <AdvancedMarker key={items.id} position={items.geo} onClick={handleMouseOver}>
                        <img src={fmf} width={32} height={32} onMouseEnter={()=>{handleMouseOver(items)}} onMouseLeave={handleMouseOut} />
                    </AdvancedMarker>
                );
            })}

            {/*
            <AdvancedMarker position={{lat: 51.511848, lng: -0.110344}} onClick={handleMouseOver} >
                <img src={fmf} width={32} height={32} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}/>

            </AdvancedMarker>
            */}
            {selectedMarker && (
                <InfoWindow position={selectedMarker.geo} style={{backgroundColor:'#6200ee'}}>
                    <div className="info-window-content">
                        <img src={selectedMarker.imageUrl} alt='sample image' className="info-window-image"  />
                        <h4>{selectedMarker.name}</h4>
                        <p>{selectedMarker.providerName}</p>
                    </div>
                </InfoWindow>
            )}
        </Map>
    </APIProvider>);
}

export default SampleMap;


