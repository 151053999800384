import React from 'react';
import { Navbar, Container, Nav } from 'react-bootstrap';
import FMFLogo from '../assets/FMF - Landscape - White Background - Border.png'
import {useNavigate} from "react-router-dom";

function Header() {

    const navigate = useNavigate();
    return (
        <>
            <Navbar className="bg-body-tertiary">
                <Container>
                    <div className="d-flex w-100 justify-content-between align-items-center">
                        <Navbar.Brand href="#home" className="ms-3">
                            <img
                                alt=""
                                src={FMFLogo}
                                width="180"
                                height="70"
                                className="d-inline-block align-items-center"
                                onClick={()=>{navigate('/')}}
                            />{' '}

                        </Navbar.Brand>
                        <Nav className="ms-auto">
                            <Nav.Link href="https://www.findmyfacility.com/sports">Sports</Nav.Link>
                            <Nav.Link href="https://www.findmyfacility.com/facilities">Facilities</Nav.Link>
                            <Nav.Link href=" https://www.findmyfacility.com/blog">Blog</Nav.Link>
                            <Nav.Link href="https://www.findmyfacility.com/contact-us">Contact</Nav.Link>
                        </Nav>
                    </div>
                </Container>
            </Navbar>
        </>
    );
}

export default Header;
