import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css'; // import calendar css
import './Event.css'
import { format } from 'date-fns';
import badminton from '../../assets/badminton.jpg'

const Event = () => {
  const { id } = useParams();
  const [eventDetails, setEventDetails] = useState(null);
  const [date, setDate] = useState(new Date());
  const [timeSlots, setTimeSlots] = useState([]);
  const [selectedTime, setSelectedTime] = useState(false);

  useEffect(() => {
    fetch(`https://api-test.findmyfacility.com/v1/events/${id}/details/slots`)
      .then(res => {
        if (!res.ok) {
          throw new Error('Error fetching details');
        }
        return res.json();
      })
      .then(data => {
        setEventDetails(data);
      })
      .catch(error => console.error(error));
  }, [id]);

  const handleDateChange = (date) => {

    setDate(date);
  };

  const handleTimeSelect = (event) => {
    setSelectedTime(!selectedTime);
  };

  const handleDaySelect = (selectedDate) =>{
    setSelectedTime(false);

    const formattedDate = format(selectedDate, 'yyyy-MM-dd');


    const slots = eventDetails.eventSlots

    const filteredTime=slots.filter((date2) => date2.startDate.includes(formattedDate) && date2.endDate.includes(formattedDate));


    const formattedTimeSlots = filteredTime.map(time => {
      const start = new Date(time.startDate);
      const end = new Date(time.endDate);
      const startTime = `${start.getUTCHours().toString().padStart(2, '0')}:${start.getUTCMinutes().toString().padStart(2, '0')}`;
      const endTime = `${end.getUTCHours().toString().padStart(2, '0')}:${end.getUTCMinutes().toString().padStart(2, '0')}`;
      return `${startTime} - ${endTime}`;
    });
    setTimeSlots(formattedTimeSlots)


  }

  return (

    eventDetails ? (
        <>
          <div className='image-container'>

            <img
                src={eventDetails.eventDetails.imageUrl ? eventDetails.eventDetails.imageUrl[0] : badminton}
                alt="Event"
                className="event-image"
            />
            <h1 className="event-heading">{eventDetails.eventDetails.organiserName}</h1>
          </div>

          <Container className="event-page">
          {/* Image at the top */}
          {/*<Row className="mb-4">
          <Col>
            <img
              src={eventDetails.imageUrl ? eventDetails.imageUrl[0] : badminton}
              alt="Event"
              className="event-image"
            />
          </Col>/*}
        </Row>

        {/* Columns with allotted space in the middle */}
        <Row className="my-4">
          <Col md={6} className="about-venue">
            <h1>About the Venue</h1>
            <p style={{textAlign:'start'}}>{eventDetails.eventDetails.organiserDescription}</p>
          </Col>
          <Col md={6} className="book-section">
            <h1>Book</h1>
            <Calendar
              onChange={handleDateChange}
              value={date}
              className="mb-4"
              onClickDay={handleDaySelect}
            />
            <div className="time-slots">
              {timeSlots && timeSlots.length > 0 ? (
                  timeSlots.map((time, index) => (
                      <Button
                          key={index}
                          variant={selectedTime ? 'primary' : 'outline-primary'}
                          onClick={handleTimeSelect}
                          className="time-slot"
                      >
                        {time}
                      </Button>
                  ))
              ) : (
                  <h1>No slots for this day</h1>
              )}

            </div>
          </Col>
        </Row>

        {/* Details */}
        <Row className="my-4 details-section">
          <h2>Details</h2>
          <Col>
            {eventDetails.eventDetails.isCoached && <p>Coached</p>}
          </Col>
          <Col>
            {eventDetails.eventDetails.membershipRequired && <p>Membership Required</p>}
          </Col>
        </Row>

        {/* Contact Details */}
        <Row className="contact-details">
          <h2>Contact Details</h2>
          <Col>
            <p>{eventDetails.eventDetails.organiserName}</p>
            <p>{eventDetails.eventDetails.organiserPhone}</p>
            <p>{eventDetails.eventDetails.address}</p>
            <p>{eventDetails.eventDetails.organiserEmail}</p>
          </Col>
        </Row>
      </Container>
        </>
    ) : (
      <h1>No data to show</h1>
    )
  );
};

export default Event;


