import logo from './logo.svg';
import './App.css';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import SearchPage from './components/SearchPage';
import SideBar from './components/SideBar';
import Events from './components/Events';
import MainPage from './components/MainPage';
import Map from './components/Map';
import ToggleBar from './components/ToggleBar';
import HomePage from './components/HomePage';
import Event from './components/EventDetails/Event';
import { useEffect, useState } from 'react';
import SampleMap from "./components/SampleMap";
import Header from "./components/Header";

function App() {

  const [type,setType] = useState('class')
  const [isBookable,setIsBookable] = useState(false)
  const [location,setLocation] =useState('51.511848,-0.110344,5')
  const [sportNr,setSportNr] = useState(0)
  const [classNr,setClassNr] = useState(0)
  const [limit,setLimit] = useState(6)
  const [page,setPage] = useState(1)
  const [ageValue, setAgeValue] = useState('Child');
  const [freeValue, setFreeValue] = useState('');
  const [virtualValue, setVirtualValue] = useState('');
  const [minAgeValue, set_minAgeValue] = useState();
  const [maxAgeValue, set_maxAgeValue] = useState();
  const [startTime, setStartTime] = useState(12);
  const [endTime, setEndTime] = useState(15);
  const [maxDuration,setMaxDuration] = useState(0)
  const [maxPrice,setMaxPrice] = useState(5)
  const [gender,setGender] = useState('')
  const [difficulty,setDifficulty] = useState([])
  const [activityTypes,setActivityTypes] = useState([])
  const [output,setOutput] = useState([])
  

  const inputDetails = {
    type: type,
    isBookable : isBookable,
    geo_radial: location,
    sportNr: sportNr,
    classNr: classNr,
    limit: limit,
    page: page,
    activityTypes:activityTypes,
    //ageGroup: ageValue,
    //isFree: freeValue,
    //isVirtual: virtualValue,
    age_lte: minAgeValue,
    age_gte: maxAgeValue,
    //startTime: startTime,
    //endTime: endTime,
    priceAdult_lte: maxPrice,
    gender: gender,
    output : output
  };

  

  const updateFilters = (newFilters) => {

    if ('selectedsports' in newFilters) {
      const sportNames=newFilters.selectedsports.map((sport)=>{
        return sport.name
      })

      setActivityTypes(sportNames)
      //setActivityTypes(newFilters.selectedsports.name);
    }
    // Update location if latitude and longitude are present
    if ('latitude' in newFilters && 'longitude' in newFilters) {
      setLocation(`${newFilters.latitude},${newFilters.longitude},5`);
    }
  
    // Update minAgeValue if present
    if ('minAgeValue' in newFilters) {
      set_minAgeValue(newFilters.minAgeValue);
    }
  
    // Update maxAgeValue if present
    if ('maxAgeValue' in newFilters) {
      set_maxAgeValue(newFilters.maxAgeValue);
    }
  
    // Update maxPrice if present
    if ('maxPrice' in newFilters) {
      setMaxPrice(newFilters.maxPrice);
    }
  
    // Update gender if present
    if ('gender2' in newFilters) {
      setGender(newFilters.gender2);
    }

    if('output2' in newFilters){
      setOutput(newFilters.output2);
    }
  };

  console.log(inputDetails)
  return (
    <Router>
      <div className="App">
        <div className="content">
          <div className="header">
            <Header/>
          <Routes>

            {/*<Route path="/sidebar" element={<SideBar />} />*/}
            {/*<Route path="/events" element={<Events />} />*/}
            <Route path="/" element={<MainPage details={inputDetails} updateFilters={updateFilters} />} />
            {/*<Route path="/map" element={<Map details={inputDetails}/>} />*/}
            <Route path="/home" element={<HomePage details={inputDetails} updateFilters={updateFilters}/>} />
            <Route path="/home/event/:id" element={<Event />} />


            {/*<Route path="/sampleMap" element={<SampleMap />} />*/}
          </Routes>

          </div>
        </div>
      </div>
    </Router>
  );
}

export default App;
