import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Button, Dropdown,Badge,CloseButton } from 'react-bootstrap';
import { usePlacesWidget } from 'react-google-autocomplete';
import ListGroup from 'react-bootstrap/ListGroup';
import '../assets/styles/SearchPage.css'
import Select from 'react-select';

const SearchPage = ({ updateFilters }) => {
  const [sports, setSports] = useState([]);
  const [selectedsports,setSelectedsports] = useState([])
  const [searchInput, setSearchInput] = useState('');
  const [filteredClasses, setFilteredClasses] = useState([]);
  const [location, setLocation] = useState('');
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);

  const updateValues = (e) => {
    e.preventDefault();
    console.log('Latitude:', latitude, 'Longitude:', longitude);
    updateFilters({ latitude, longitude,selectedsports});
  };

  useEffect(() => {
    fetch('https://api.findmyfacility.com/v1/sports')
      .then(res => {
        if (!res.ok) {
          throw new Error('Error calling the Sports API');
        }
        return res.json();
      })
      .then(data => {
        setSports(data.sports.rows);
      })
      .catch(error => console.error(error));
  }, []);

  const handleSearchClass = (value) => {
    setSearchInput(value);
    if (value === '') {
      setFilteredClasses([]);
    } else {
      const filtered = sports.filter((cls) =>
        cls.name.toLowerCase().startsWith(value.toLowerCase())
      );
      setFilteredClasses(filtered);
    }
  };

  const handleDropDownClick = (value) => {
    if (!selectedsports.some((sport) => sport.id === value.id)) {
      setSelectedsports([...selectedsports, value]);
    }
    setSearchInput('');
    setFilteredClasses([]);
  };

  const handlePlaceSelect = (place) => {
    console.log('Place selected:', place);
    setLocation(place.formatted_address);
    const lat = place.geometry.location.lat();
    const lng = place.geometry.location.lng();

    console.log('Latitude from place:', lat, 'Longitude from place:', lng);

    setLatitude(lat);
    setLongitude(lng);
  };
  const handleRemoveSport = (id) => {
    setSelectedsports(selectedsports.filter((sport) => sport.id !== id));
  };

  const { ref: locationRef } = usePlacesWidget({
    apiKey: 'AIzaSyCjYemnQG_XW3JLmu3HifVheyWXFrFrjf4',
    onPlaceSelected: (place) => handlePlaceSelect(place),
    options: {
      types: ['(cities)'],
      componentRestrictions: { country: 'uk' }, // Restrict to specific country if needed
    },
  });

  
  return (
      <>
        { /*
      <div className="search-page">
        <h1 className="search-title">Start a new search</h1>
        <p>Discover a UK sport or class at a facility near you , or even where <br/> you're planning to go</p>
        <div className="search-form">
          <Form className="w-50">
            <Row className="align-items-center">
            <div className="d-flex flex-wrap">
              <Col>
              
              <Form.Control
              placeholder="Type a sport or class"
              value={searchInput}
              onChange={(e) => handleSearchClass(e.target.value)}
              className='mb-4'
            />
              <div className="d-flex flex-wrap">
              {selectedsports.map((sport) => (
                <Badge key={sport.id} bg="primary" className="me-2 mb-2 d-flex align-items-center">
                {sport.name}
                <CloseButton
                  onClick={() => handleRemoveSport(sport.id)}
                  variant="white"
                  aria-label="Remove"
                  className="ms-2"
                />
              </Badge>
              ))}
            </div>
            {filteredClasses.length > 0 && (
              <Dropdown.Menu show >
                {filteredClasses.map((cls) => (
                  <Dropdown.Item key={cls.id} onClick={() => handleDropDownClick(cls)}>
                    {cls.name}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            )}
              
              </Col>
              <Col >
                <Form.Control
                  ref={locationRef}
                  placeholder="Type a location"
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                />
              </Col>
              <Col  >
                <Button as="input" type="submit" value="Search" onClick={(e) => { updateValues(e); }} />
              </Col>
              </div>
            </Row>
          </Form>
        </div>
      </div>
      */}
        <div className="search-page">
          <div className="search-form">
            <h1 className="search-title">FIND A SPORT OR CLASS</h1>
            <p className="para mt-4">
              Discover a UK sport or class at a facility near you, or even where
              <br/>
              you're planning to go
            </p>
            <Form className="w-50 mt-4">
              <Row className="align-items-center justify-content-center">
                <Col xs={12} className="mb-3">
                  <Form.Control
                      placeholder="Type a sport or class"
                      value={searchInput}
                      onChange={(e) => handleSearchClass(e.target.value)}
                  />
                </Col>
                <Col xs={12} className="mb-3">
                  <div className="d-flex flex-wrap justify-content-center">
                    {selectedsports.map((sport) => (
                        <Badge key={sport.id} bg="primary" className="me-2 mb-2 d-flex align-items-center">
                          {sport.name}
                          <CloseButton
                              onClick={() => handleRemoveSport(sport.id)}
                              variant="white"
                              aria-label="Remove"
                              className="ms-2"
                          />
                        </Badge>
                    ))}
                  </div>
                </Col>
                {filteredClasses.length > 0 && (
                    <Col xs={12} className="mb-3">
                      <Dropdown.Menu show>
                        {filteredClasses.map((cls) => (
                            <Dropdown.Item key={cls.id} onClick={() => handleDropDownClick(cls)}>
                              {cls.name}
                            </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Col>
                )}
                <Col xs={12} className="mb-3">
                  <Form.Control
                      ref={locationRef}
                      placeholder="Type a location"
                      value={location}
                      onChange={(e) => setLocation(e.target.value)}
                  />
                </Col>
                <Col xs={12} className="text-center">
                  <Button as="input" type="submit" value="Search" onClick={(e) => updateValues(e)}/>
                </Col>
              </Row>
            </Form>
            <a href="" className="dld">Or download the app</a>
          </div>
        </div>
      </>
  );
};

export default SearchPage;
