import React, { useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import 'bootstrap/dist/css/bootstrap.min.css';
import MultiRangeSlider from 'multi-range-slider-react';
import { useState } from 'react';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import '../assets/styles/SideBar.css'

const SideBar = ({ updateFilters, details }) => {
  const { priceAdult_lte, age_lte, age_gte, gender } = details;
  const [maxDuration, setMaxDuration] = useState(0);
  const [maxPrice, setMaxPrice] = useState(priceAdult_lte);
  const [gender2, setGender2] = useState(gender);
  const [difficulty, setDifficulty] = useState([]);
  const [tempMaxPrice, setTempMaxPrice] = useState(maxPrice);
  const [minAgeValue, setMinAgeValue] = useState(age_lte);
  const [maxAgeValue, setMaxAgeValue] = useState(age_gte);
  const [startTime, setStartTime] = useState(12);
  const [endTime, setEndTime] = useState(15);
  const [ageValue, setAgeValue] = useState('Child');
  const [freeValue, setFreeValue] = useState('');
  const [virtualValue, setVirtualValue] = useState('');

  const handleSliderChange = (e) => {
    setTempMaxPrice(e.target.value);
  };

  const handleSliderMouseUp = (e) => {
    setMaxPrice(e.target.value);
  };

  const handleChange = (value) => {
    setDifficulty((prevDifficulty) =>
      prevDifficulty.includes(value)
        ? prevDifficulty.filter((diff) => diff !== value)
        : [...prevDifficulty, value]
    );
  };

  const handleRadioChange = (value) => {
    setGender2(value);
  };

  const handleAgeInput = (e) => {
    setMinAgeValue(e.minValue);
    setMaxAgeValue(e.maxValue);
  };

  const handleTimeInput = (e) => {
    setStartTime(e.minValue);
    setEndTime(e.maxValue);
  };

  useEffect(() => {
    updateFilters({ minAgeValue, maxAgeValue, maxPrice, gender2, difficulty });
  }, [minAgeValue, maxAgeValue, maxPrice, gender2, difficulty, updateFilters]);

  return (
    <Container>
    <div className="d-flex mb-3 align-items-start w-100">
    <Form.Group className="w-100">
      <Form.Label className="text-start w-100 mb-4" style={{ fontWeight: 'bold' }}>Max Price: {maxPrice}</Form.Label>
      <Form.Range value={tempMaxPrice} onChange={handleSliderChange} onMouseUp={handleSliderMouseUp} />
    </Form.Group>
  </div>

  <div className="d-flex mb-3 align-items-start w-100">
    <Form.Group className="w-100">
      <Form.Label className="text-start w-100 mb-4" style={{ fontWeight: 'bold' }}>Max Duration: {maxDuration}</Form.Label>
      <Form.Range value={maxDuration} onChange={(e) => setMaxDuration(e.target.value)} />
    </Form.Group>
  </div>
      <div className="d-flex mb-3 ">
        <Form>
          <Form.Label className="text-start w-100 mb-2" style={{ fontWeight: 'bold' }}>Genders</Form.Label>
          <p className="text-start w-100 mb-2">Show only events for these genders</p>
          <Row>
            <Col>
              <Form.Check type="radio" label="Male only" checked={gender2 === 'MaleOnly'} onChange={() => handleRadioChange('MaleOnly')} />
            </Col>
            <Col>
              <Form.Check type="radio" label="Female only" checked={gender2 === 'FemaleOnly'} onChange={() => handleRadioChange('FemaleOnly')} />
            </Col>
          </Row>
        </Form>
    </div>
      <div className="d-flex mb-3">
        <Form>
          <Form.Label className="text-start w-100 mb-2" style={{ fontWeight: 'bold' }}>Difficulty</Form.Label>
          <p className="text-start w-100 mb-2">Show only these difficulty levels</p>
          <Row>
            <Col>
              <Form.Check type="checkbox" label="Beginner" checked={difficulty.includes('beginner')} onChange={() => handleChange('beginner')} />
            </Col>
            <Col>
              <Form.Check type="checkbox" label="Intermediate" checked={difficulty.includes('intermediate')} onChange={() => handleChange('intermediate')} />
            </Col>
            <Col>
              <Form.Check type="checkbox" label="Advanced" checked={difficulty.includes('advance')} onChange={() => handleChange('advance')} />
            </Col>
          </Row>
        </Form>
      </div>
      <div className="d-flex flex-column mb-3  text-start">
        <Form.Label style={{ fontWeight: 'bold' }}>Start Time: {startTime} - {endTime}</Form.Label>
        <p>Drag Circles to change start/end times</p>
        <MultiRangeSlider
          min={0}
          max={100}
          step={5}
          minValue={startTime}
          maxValue={endTime}
          onInput={handleTimeInput}
          ruler={false}
        />
      </div>
      <div className="d-flex flex-column mb-3  text-start">
        <Form.Label style={{ fontWeight: 'bold' }}>Age Range: {minAgeValue} : {maxAgeValue}</Form.Label>
        <p>Drag Circles for min and max ages allowed</p>
        <MultiRangeSlider
          min={0}
          max={100}
          step={5}
          minValue={minAgeValue}
          maxValue={maxAgeValue}
          onMouseUp={handleAgeInput}
          ruler={false}
        />
      </div>
      <Container className='px-0'>
  <Row className="mb-3 align-items-start w-100">
    <Col xs={6} md={5} className="mb-3 text-start align-items-center">
      <Form.Label className="text-start">Only for children or adults?</Form.Label>
    </Col>
    <Col xs={6} md={5} className="mb-3 text-start">
      <ButtonGroup >
        {['Child', 'Adult'].map((radio, idx) => (
          <ToggleButton
            key={idx}
            id={`age-${idx}`}
            type="radio"
            variant={idx % 2 ? 'outline-success' : 'outline-danger'}
            name="age"
            value={radio}
            checked={ageValue === radio}
            onChange={(e) => setAgeValue(e.target.value)}
          >
            {radio}
          </ToggleButton>
        ))}
      </ButtonGroup>
    </Col>
  </Row>

  
  <Row className="mb-3 align-items-start">
    <Col xs={6} md={5} className="mb-3 text-start align-items-center">
      <Form.Label className="text-start">Show only free events?</Form.Label>
    </Col>
    <Col xs={6} md={5} className="mb-3 text-start">
      <ButtonGroup>
        {['Yes', 'No'].map((radio, idx) => (
          <ToggleButton
            key={idx}
            id={`free-${idx}`}
            type="radio"
            variant={idx % 2 ? 'outline-success' : 'outline-danger'}
            name="free"
            value={radio}
            checked={freeValue === radio}
            onChange={(e) => setFreeValue(e.target.value)}
          >
            {radio}
          </ToggleButton>
        ))}
      </ButtonGroup>
    </Col>
  </Row>
  
  <Row className="mb-3 align-items-start">
    <Col xs={12} className="d-flex flex-column">
      <Form.Label className="text-start mb-2" style={{ fontWeight: 'bold' }}>Virtual only</Form.Label>
      <div className="d-flex align-items-center">
        <p className="mb-0 me-4">Show only online events?</p>
        <ButtonGroup>
          {['Yes', 'No'].map((radio, idx) => (
            <ToggleButton
              key={idx}
              id={`virtual-${idx}`}
              type="radio"
              variant={idx % 2 ? 'outline-success' : 'outline-danger'}
              name="virtual"
              value={radio}
              checked={virtualValue === radio}
              onChange={(e) => setVirtualValue(e.target.value)}
            >
              {radio}
            </ToggleButton>
          ))}
        </ButtonGroup>
      </div>
    </Col>
  </Row>
</Container>

    </Container>
  );
};

export default SideBar;
